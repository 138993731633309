import { defineStore } from 'pinia'
import {
    CapturePhotoModel,
    IPhotoModel,
    Photo,
} from '../models/CapturePhotoModel.ts'
import axios from 'axios'
import Dexie, { Table } from 'dexie'
import { retry } from '../common/retry.ts'
import { fetchAuthSession } from 'aws-amplify/auth'
import { SignatureV4, prepareRequest } from '@smithy/signature-v4'
import { Sha256 } from '@aws-crypto/sha256-js'

class SnapPixDatabase extends Dexie {
    photos!: Table<Photo>

    constructor() {
        super('snapPixDatabase')
        this.version(1).stores({
            photos: '++id, eventId, deviceId, base64, uploaded', // Primary key and indexed props
        })
    }
}

const db = new SnapPixDatabase()

interface State {
    photos: IPhotoModel[]
    photosLoading: boolean
}

async function signRequest(
    url: string,
    body: string | ArrayBuffer | { [key: string]: string },
    method: string,
    service: string,
    region: string
) {
    const awsCredentials = (await fetchAuthSession()).credentials

    if (!awsCredentials) {
        throw new Error('No AWS credentials found')
    }

    const credentials = {
        secretAccessKey: awsCredentials.secretAccessKey,
        accessKeyId: awsCredentials.accessKeyId,
        sessionToken: awsCredentials.sessionToken,
    }

    // set your region and service here
    const serviceInfo = { region, service }

    const signer = new SignatureV4({
        service: serviceInfo.service,
        region: serviceInfo.region,
        credentials: credentials,
        sha256: Sha256,
    })

    const parsedUrl = new URL(url)
    const endpoint = parsedUrl.hostname.toString()
    const path = parsedUrl.pathname.toString()

    const request = await signer.sign(
        prepareRequest({
            hostname: endpoint,
            path,
            body,
            protocol: parsedUrl.protocol.toString(),
            method: method,
            headers: {
                ':authority': endpoint,
            },
        }),
        {
            unsignableHeaders: new Set(['host']),
            signableHeaders: new Set([':authority']),
        }
    )

    delete request.headers[':authority'] // need to delete this header as it is not allowed in the axios request

    return request
}

export const usePhotoStore = defineStore('PhotoStore', {
    state: (): State => ({
        /** @type: EventModel[] */
        photos: [],
        /** @type: boolean */
        photosLoading: false,
    }),
    getters: {
        count: (state: State) => state.photos.length,
    },
    actions: {
        async createPhoto(
            eventId: string,
            deviceId: string,
            photo: {
                blob: Blob
                image_data_url: string
            }
        ) {
            const id = await db.photos.add({
                eventId,
                deviceId,
                base64: photo.image_data_url,
                uploaded: false,
            })

            console.log(`Photo added with id ${id}`)

            this.photos.splice(
                0,
                0,
                CapturePhotoModel.fromDTO({
                    id,
                    eventId,
                    deviceId,
                    base64: photo.image_data_url,
                    uploaded: false,
                })
            )

            await this.uploadPhoto(id, eventId, deviceId, photo)

            return id
        },
        async uploadPhoto(
            id: number,
            eventId: string,
            deviceId: string,
            photo: {
                blob: Blob
                image_data_url: string
            }
        ) {
            const fileName = `${deviceId}-${new Date().getTime()}.jpeg`
            let uploadSuccessful = false

            const sendApiRequest = async () => {
                try {
                    const url = `${import.meta.env.VITE_API_ENDPOINT}CapturePhoto/${eventId}/${fileName}`
                    const method = 'PUT'

                    const signedRequest = await signRequest(
                        url,
                        await photo.blob.arrayBuffer(),
                        method,
                        'execute-api',
                        'eu-west-1'
                    )

                    const restOperation = axios.put(
                        `CapturePhoto/${eventId}/${fileName}`,
                        new File([photo.blob], fileName),
                        {
                            baseURL: import.meta.env.VITE_API_ENDPOINT,
                            headers: {
                                'Content-Type': 'image/jpeg',
                                ...signedRequest.headers,
                            },
                        }
                    )

                    const { data } = await restOperation

                    console.log('POST call succeeded')
                    console.log(data)

                    uploadSuccessful = true
                } catch (e) {
                    console.log('POST call failed: ', e)
                    throw e
                }
            }

            await retry(sendApiRequest, [], 100, 3000)

            if (uploadSuccessful) {
                await db.photos.update(id, { uploaded: true })

                const photoIndex = this.photos.findIndex(
                    (photo) => photo.id === id
                )

                this.photos[photoIndex].markUploaded()
            }

            return
        },
        async fetchPhotos(eventId: string) {
            try {
                this.photosLoading = true

                const photos = await db.photos
                    .filter((photo) => photo.eventId === eventId)
                    .reverse()
                    .toArray()

                console.log(photos)

                this.photos = photos.map((photo) =>
                    CapturePhotoModel.fromDTO(photo)
                )

                const photosNeedUploading = this.photos.filter(
                    (photo) => !photo.uploaded
                )

                for (const photo of photosNeedUploading) {
                    const response = await fetch(photo.base64Image)
                    const blob = await response.blob()

                    await this.uploadPhoto(
                        photo.id,
                        photo.eventId,
                        photo.deviceId,
                        { blob: blob, image_data_url: photo.base64Image }
                    )
                }
            } catch (error) {
                console.error(error)
                return undefined
            } finally {
                this.photosLoading = false
            }
        },
    },
})
